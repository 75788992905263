import React from "react";
import { Link } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
// import { Map } from "../../common/Map";
import { Upcoming } from "../Upcoming";
import loadable from "@loadable/component";
import { BackgroundImage } from "./BackgroundImage";
// import { BackgroundVideo } from "./BackgroundVideo";

import * as styles from "./HomePage.module.scss";

const HomePage = ({ home, exhibitions }) => {
  const image = home.artworks[0];
  const BackgroundVideo = loadable(() => import("./BackgroundVideo"), {
    fallback: <div className="visually-hidden">loading background</div>,
  });
  return (
    <>
      <div className={styles.home}>
        <h1 className="visually-hidden">Alistair McClymont</h1>
        <div className={styles.homeInfo}>
          {/* <h2>
            <span>
              Alistair McClymont is an artist working in the United Kingdom.
              View McClymont's <Link to="/artwork">artwork</Link>, see the{" "}
              <Link to="/studio">studio</Link>, view the{" "}
              <Link to="/biography">biography</Link>, or{" "}
              <Link to="/contact">
                contact him, or subscribe to the mailing list
              </Link>
              .
            </span>
          </h2> */}
          <Upcoming exhibitions={exhibitions} />
        </div>
        <div className={styles.bio}>
          <p>
            Underlying Alistair McClymont’s interdisciplinary creative practice
            is a deep concern for beauty and reason, explored through
            collaboration with scientists. Many of his artworks take the form of
            direct demonstration or experimentation: by isolating phenomena,
            McClymont reimagines them, highlighting the balance between order
            and chaos, while questioning humanity's role in a science-defined
            era.
          </p>
          <p>
            His work spans a variety of media from sculptural installation,
            painting, and photography, to video, new media digital art, and the
            publication of scientific research papers.{" "}
          </p>
          <p>
            Many of his Artworks take the form of direct demonstration or
            experimentation. By isolating phenomena from the world, McClymont
            represents and re-evaluates them in novel creative contexts.
            Concepts are allowed to define the forms they take, and projects are
            designed to highlight the delicate, generative balance between order
            and chaos in the very systems used to create them.
          </p>
        </div>
      </div>
      <BackgroundVideo />
      {/* <BackgroundImage image={image} /> */}
    </>
  );
};

export { HomePage };
